// @flow
import React from "react";
import { SvgIcon } from "@mui/material";

const LoginPasswordIcon = () => (
  <SvgIcon viewBox="0 0 24 24">
    <path
      d="M12.4472 22.8944C12.1657 23.0352 11.8343 23.0352 11.5528 22.8944C11.2759 22.756 10.8111 22.4987 10.22 22.1292C9.23988 21.5167 8.25994 20.8062 7.3415 20.0026C4.64576 17.6438 3 14.9694 3 12V4C3 3.54113 3.3123 3.14115 3.75746 3.02986L11.7575 1.02986C11.9167 0.990047 12.0833 0.990047 12.2425 1.02986L20.2425 3.02986C20.6877 3.14115 21 3.54113 21 4V12C21 14.9694 19.3542 17.6438 16.6585 20.0026C15.7401 20.8062 14.7601 21.5167 13.78 22.1292C13.1889 22.4987 12.7241 22.756 12.4472 22.8944ZM12.72 20.4333C13.6149 19.874 14.5099 19.225 15.3415 18.4974C17.6458 16.4812 19 14.2806 19 12V4.78078L12 3.03078L5 4.78078V12C5 14.2806 6.35424 16.4812 8.6585 18.4974C9.49006 19.225 10.3851 19.874 11.28 20.4333C11.5371 20.594 11.7786 20.7373 12 20.8628C12.2214 20.7373 12.4629 20.594 12.72 20.4333Z"
    />
  </SvgIcon>
);

export default LoginPasswordIcon;
