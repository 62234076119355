// @flow
/* eslint-disable import/max-dependencies */
import React, { type Element, Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import AppContainer from "../../containers/App";
import defaultTheme from "../../theme";

function App(): Element<typeof Fragment> {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
