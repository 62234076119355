// @flow
import {
  type Theme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import DateRangeDateCell from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDateCell";

type Classes = {
  root: string,
  disabled: string,
  startEndSelectedDay: string,
  selectedDay: string,
  otherMonth: string,
};

const DateCellComponent = withStyles((theme: Theme): { [$Keys<Classes>]: *} => ({
  root: {
    margin: "1px",
    borderRadius: "5px",
    width: "35px",
    height: "34px",
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
    pointerEvents: "none",
    "& .MuiTypography-body1": {
      fontWeight: 400,
    },
  },
}))(DateRangeDateCell);

export default DateCellComponent;
