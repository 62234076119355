// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useState,
  useEffect,
  type StatelessFunctionalComponent,
} from "react";
import {
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import BannerDialog from "./BannerDialog";
import ActiveBrandsFilters from "./ActiveBrandsFilters";
import ActiveBrandsTable from "./ActiveBrandsTable";
import type { ActiveBrandsPayloadItem } from "../../containers/ActiveBrands/types/ActiveBrands.types";
import type { Props, UseState } from "./types/ActiveBrands.types";

const ActiveBrands: StatelessFunctionalComponent<Props> = ({
  data,
  page,
  total,
  pageSize,
  filters,
  verticalsDropdown,
  domainsDropdown,
  nichesDropdown,
  platformsDropdown,
  loading,
  dropdownLoading,
  onChangeTablePage,
  onChangeTablePageSize,
  onChangeTableFilters,
  onGetActiveBrandsData,
  onGetVerticalsDropdownData,
  onGetNichesDropdownData,
  onGetPlatformsDropdownData,
  onGetDomainsDropdownData,
}: Props) => {
  const [opened, setOpened]: UseState<boolean> = useState(false);
  const [dialogBanner, setDialogBanner]: UseState<string> = useState("");
  const [dialogTitle, setDialogTitle]: UseState<string> = useState("");

  useEffect(() => {
    onGetActiveBrandsData();
  }, [onGetActiveBrandsData, page, pageSize]);

  useEffect(() => {
    onGetVerticalsDropdownData();
    onGetNichesDropdownData();
    onGetPlatformsDropdownData();
    onGetDomainsDropdownData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopyUrl: (value: string) => void = (value) => {
    navigator.clipboard.writeText(value);
  };

  const handleOpenBannerDialog: (row: ActiveBrandsPayloadItem) => void = ({ image, domain }) => {
    setDialogBanner(image);
    setDialogTitle(domain);
    setOpened(true);
  };

  const handleCloseBannerDialog: () => void = () => {
    setOpened(false);
    setDialogBanner("");
    setDialogTitle("");
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ActiveBrandsFilters
          filters={filters}
          domainsDropdown={domainsDropdown}
          verticalsDropdown={verticalsDropdown}
          nichesDropdown={nichesDropdown}
          platformsDropdown={platformsDropdown}
          loading={loading}
          dropdownLoading={dropdownLoading}
          onChangeTableFilters={onChangeTableFilters}
          onGetActiveBrandsData={onGetActiveBrandsData}
        />
        { (loading || dropdownLoading) && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { !data.length && !loading && (
          <Grid container justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Grid>
        )}
        { data.length > 0 && !loading && (
          <>
            <ActiveBrandsTable
              data={data}
              total={total}
              page={page}
              pageSize={pageSize}
              onChangeTablePage={onChangeTablePage}
              onChangeTablePageSize={onChangeTablePageSize}
              onCopyUrl={handleCopyUrl}
              onOpenBannerDialog={handleOpenBannerDialog}
            />
            <BannerDialog
              open={opened}
              banner={dialogBanner}
              title={dialogTitle}
              handleClose={handleCloseBannerDialog}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ActiveBrands;
