// @flow
import React, {
  useState,
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  BarChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import dayjs from "dayjs";
import type { ItemData } from "../../services/dashboardApi";

export const formatDate = (v: string): string => (dayjs(v, "DD MM YY").isValid() ? dayjs(v, "DD MM YY").format("DD MMM") : v);
export const formatNumber = (v: number): string => (v >= 1000 ? `${v / 1000}k` : String(v));

type Props = {
  data: Array<ItemData>,
}

const getFallbackData: () => ItemData[] = () => [
  { amount: 0, date: "" },
];

const initialState: {
  amount: number,
  quantity: number,
} = {
  amount: 1,
  quantity: 1,
};

const Chart: StatelessFunctionalComponent<Props> = ({
  data,
}: Props) => {
  const [opacity, setOpacity] = useState(initialState);
  const commonBarProps = {
    radius: [7, 7, 0, 0],
    barSize: 50,
  };

  const handleMouseEnter = (e) => {
    const { dataKey } = e;

    setOpacity((prev) => ({
      ...prev,
      [dataKey]: 0.5,
    }));
  };

  const handleMouseLeave: () => void = () => {
    setOpacity(initialState);
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={350} minWidth={300}>
      <BarChart
        data={data.length ? data : getFallbackData()}
        margin={{
          top: 30, right: 60, left: 0, bottom: 30,
        }}
      >
        <Tooltip labelFormatter={formatDate} />
        <XAxis dataKey="date" tickFormatter={formatDate} />
        <YAxis tickFormatter={formatNumber} />
        <Legend
          wrapperStyle={{ top: 0, left: 0 }}
          verticalAlign="top"
          align="center"
          formatter={(value: string): Node => <span style={{ color: "#000" }}>{value}</span>}
          iconType="circle"
          iconSize={10}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />

        <Bar {...commonBarProps} dataKey="amount" name="Commission" fill={`rgba(160, 85, 221, ${opacity.amount})`} />
        <Bar {...commonBarProps} dataKey="quantity" name="Leads" fill={`rgba(255, 186, 8, ${opacity.quantity})`} />

      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
