/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect,
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Chart from "../Chart";
import { TableComponent } from "../TableComponent";
import DateRangePickerComponent from "../DateRangePickerComponent";
import type { Props } from "../../containers/Dashboard/types/Dashboard.types";
import type { Column } from "../TableComponent/types/TableComponent.types";
import type { ItemData, TotalData } from "../../services/dashboardApi";

type Classes = {
  row: string,
  dateCell: string,
  amountCell: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: *} => ({
  row: {
    "&:last-of-type": {
      backgroundColor: theme.palette.background.default,
    },
    "&:last-of-type td": {
      fontWeight: 600,
    },
  },
  dateCell: {
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: "5px",
    backgroundColor: theme.palette.background.default,
  },
  amountCell: {
    color: theme.palette.success.main,
  },
}));

const Dashboard: StatelessFunctionalComponent<Props> = ({
  data = [],
  dataTotal,
  filters,
  onGetDashboardData,
  onFiltersChange,
  loading,
}: Props) => {
  const classes: Classes = useStyles();

  const columns: Array<Column<ItemData>> = [
    {
      field: "date",
      label: "Date",
      key: "date",
      render: ({ row: { date } }): Node => (
        <Box className={classes.dateCell}>{date}</Box>
      ),
    },
    {
      field: "quantity",
      label: "Leads",
      key: "quantity",
    },
    {
      field: "cost",
      label: "Average CPL, €",
      key: "cost",
      render: ({ row: { cost } }: { row: ItemData }): string => (cost ? `${cost}` : ""),
    },
    {
      field: "amount",
      label: "Total Commission, €",
      key: "amount",
      render: ({ row: { amount } }): Node => (
        <Typography className={classes.amountCell}>{amount ? `€${amount}` : ""}</Typography>
      ),
    },
  ];
  const totalColumns: Array<Column<TotalData>> = [
    {
      field: "",
      label: "",
      key: "total",
      render: (): string => "Total",
    },
    {
      field: "quantity",
      label: "",
      key: "quantity",
    },
    {
      field: "cost",
      label: "",
      key: "cost",
      render: (): string => "-",
    },
    {
      field: "amount",
      label: "",
      sortable: true,
      key: "amount",
      className: classes.amountCell,
      render: ({ row: { amount } }: { row: TotalData }): string => (amount ? `€${amount}` : ""),
    },
  ];

  useEffect(() => {
    if (data.length === 0) {
      onGetDashboardData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Box pt={1} pl={3} width={1} display="flex" justifyContent="space-between">
            <Typography variant="h3">Chart report</Typography>
            <Box display="flex">
              <DateRangePickerComponent filters={filters} onFiltersChange={onFiltersChange} disabled={loading} />
              <Box pl={1}>
                <Button variant="contained" color="primary" disabled={loading} onClick={onGetDashboardData}>
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
          <Chart data={data} />
        </Box>
        { loading && (
          <Box width={1} display="flex" mt={3} justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        { isShowNoDataMessage && (
          <Box width={1} display="flex" mt={3} justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Box>
        )}
        { isShowData && (
          <>
            <Box mt={3} p={3}>
              <Box pb={2}>
                <Typography variant="h3">Table report</Typography>
              </Box>
              <TableComponent
                classes={{
                  row: classes.row,
                }}
                columns={columns}
                data={data}
                totalColumns={totalColumns}
                totalData={dataTotal}
              />
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
