// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  type TextFieldProps,
  type Theme,
} from "@mui/material";
import {
  LayersOutlined,
  ExploreOutlined,
  DescriptionOutlined,
  DevicesOutlined,
} from "@mui/icons-material";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import type { PropsInputComponent } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";
import dayjs from "dayjs";
import en from "dayjs/locale/en-au";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import {
  DateCellComponent,
  DateInputComponent,
  FooterComponent,
} from "../DateRangePickerComponent";
import { makeStylesTyped, mapOptions } from "../../helpers/generators";

type Props = {
  filters: Filters,
  loading: boolean,
  onChangeTableFilters: (string, Filters) => mixed,
  onGetData: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
}

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

const useStyles = makeStylesTyped((theme: Theme) => ({
  input: {
    width: "180px",
  },
  dateRange: {
    width: "253px",
    "& textarea": {
      textAlign: "center",
    },
  },
  label: {
    fontWeight: 600,
    paddingBottom: theme.spacing(1),
  },
}));

const TransactionReportFilters: StatelessFunctionalComponent<Props> = ({
  filters,
  loading,
  onChangeTableFilters,
  onGetData,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
}: Props) => {
  const classes = useStyles();

  const handleFiltersChange = (newFilters: Filters) => {
    onChangeTableFilters(TRANSACTION_TABLE, newFilters);
  };

  const handleRangeChangeDateRangePicker = ({ startDate, endDate }) => {
    onChangeTableFilters(TRANSACTION_TABLE, { dateFrom: startDate, dateTo: endDate });
  };

  const dynamicFieldsOptions: DropDownObjItemType[] = [
    { value: "customer", label: "Company Name" },
  ];

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <FilterBuilder
          data-testid="filters"
          WrapperItemComponent={FilterWrapper}
          // $FlowFixMe
          filters={filters}
          onFiltersChange={handleFiltersChange}
          items={[
            {
              type: "select",
              filterKey: "department",
              filterProps: {
                label: "Department",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-department",
                options: [
                  { value: "", label: "All" },
                  ...departmentsDropdown,
                ].map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <LayersOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "select",
              filterKey: "country",
              filterProps: {
                label: "Country",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-country",
                options: [
                  { value: "", label: "All" },
                  ...countriesDropdown,
                ].map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <ExploreOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "select",
              filterKey: "customer",
              filterProps: {
                label: "Company Name",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-customer",
                options: [
                  { value: "", label: "All" },
                  ...customersDropdown,
                ].map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <DescriptionOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "textField",
              filterKey: "invoiceId",
              filterProps: {
                label: "Transaction id",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                type: "number",
                "data-testid": "filter-invoiceId",
                // renderInput: (props: TextFieldProps): Node => (
                //   <TextField
                //     {...props}
                //     variant="filled"
                //   />
                // ),
              },
            },
            {
              type: "select",
              filterKey: "platform",
              filterProps: {
                label: "Platform",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-platform",
                options: [
                  { value: "", label: "All" },
                  ...platformsDropdown,
                ].map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <DevicesOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "select",
              filterKey: "age",
              filterProps: {
                label: "Age Group",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-age",
                options: [
                  { value: "", label: "All" },
                  ...agesDropdown,
                ].map(mapOptions),
                renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                  <TextField
                    {...rest}
                    variant="filled"
                    InputProps={{
                      ...InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start" variant="standard">
                          <Typography variant="h6">#</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              type: "dateRangePicker",
              filterKey: "dateRangePicker",
              filterProps: {
                disabled: loading,
                classes: { input: { root: classes.dateRange }, formLabel: classes.label },
                label: "Time Period",
                startDate: filters.dateFrom,
                endDate: filters.dateTo,
                onChangeDate: handleRangeChangeDateRangePicker,
                onChangePreset: handleRangeChangeDateRangePicker,
                locale: en,
                formatDate: (date) => dayjs(date).format("DD-MM-YYYY"),
                "data-testid": "filter-date-range-picker",
                DateCellComponent,
                FooterComponent,
                DateInputComponent: ({
                  startDate, endDate, onIconClick, ...rest
                }: PropsInputComponent) => (
                  <DateInputComponent
                    {...rest}
                    startDate={startDate}
                    endDate={endDate}
                    onIconClick={onIconClick}
                  />
                ),
              },
            },
          ]}
        />
        { isAdvancedFilterEnabled && (
          <Box>
            <FilterBuilder
              WrapperItemComponent={FilterWrapper}
              // $FlowFixMe wrong type
              filters={filters}
              onFiltersChange={handleFiltersChange}
              items={[
                {
                  type: "select",
                  filterKey: "currency",
                  filterProps: {
                    label: "Conversion currency",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-currency",
                    disableClearable: true,
                    options: [
                      { value: "$", label: "Only in $" },
                      { value: "€", label: "Only in €" },
                      { value: "£", label: "Only in £" },
                    ].map(mapOptions),
                    renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                      <TextField
                        {...rest}
                        variant="filled"
                        InputProps={{
                          ...InputProps,
                          disableUnderline: true,
                        }}
                      />
                    ),
                  },
                },
                {
                  type: "multiSelect",
                  filterKey: "dynamicFields",
                  filterProps: {
                    label: "Show columns",
                    className: classes.dateRange,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-dynamicFields",
                    options: dynamicFieldsOptions.map(mapOptions),
                    renderInput: ({ InputProps, ...rest }: TextFieldProps): Node => (
                      <TextField
                        {...rest}
                        variant="filled"
                        InputProps={{
                          ...InputProps,
                          disableUnderline: true,
                        }}
                      />
                    ),
                  },
                },
              ]}
            />
          </Box>
        )}
      </Box>
      <Box alignSelf="flex-start" display="flex" pt={2} flexShrink={0}>
        <Box pr={1}>
          <Button
            variant="contained"
            color={isAdvancedFilterEnabled ? "primary" : "secondary"}
            name="advancedFilter"
            onClick={() => {
              setIsAdvancedFilterEnabled(!isAdvancedFilterEnabled);
            }}
          >
            Advanced filter
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            name="applyFilters"
            disabled={loading}
            onClick={onGetData}
          >
            Apply filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionReportFilters;
