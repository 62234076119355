// @flow
import React, { type StatelessFunctionalComponent, useState } from "react";
import {
  Box, Card, Container, Grid, Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { makeStylesTyped } from "../../helpers/generators";
import { isAuthenticated, setAuthToken } from "../../services/request";
import AuthField from "./AuthField";
import SelectProductCompanyField from "./SelectProductCompanyField";
import { setAuthDataSaga } from "../../actions/auth";

type Props = {
  location: Object,
  history: *,
  onLogin: typeof setAuthDataSaga,
}

const useStyles = makeStylesTyped((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    paddingBottom: theme.spacing(2),
  },
}));

const Login: StatelessFunctionalComponent<Props> = ({ location, history, onLogin }: Props) => {
  const classes = useStyles();
  const [loginData, setLoginData] = useState<{token: string, expires: number} | void>();

  if (isAuthenticated()) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Navigate to={from} />;
  }

  const handleSubmit = (companies: string[]) => {
    if (loginData) {
      const { from } = location.state || { from: { pathname: "/" } };
      onLogin({
        history,
        companies,
        redirect: from.pathname,
        expires: new Date(loginData.expires * 1000),
      });
    }
  };

  return (
    <Container component="main" className={classes.container}>
      <Grid container>
        <Grid item xs={6}>
          <Card elevation={0}>
            <Box display="flex" flexDirection="column" alignItems="center" p={2}>
              <Typography variant="h2" className={classes.title}>
                Log In
              </Typography>
              { !loginData ? (
                <AuthField onNext={(data: {expires: number, token: string}) => {
                  setAuthToken(data.token, new Date(data.expires * 1000));
                  setLoginData(data);
                }}
                />
              ) : (
                <SelectProductCompanyField onNext={handleSubmit} />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <video src="/page-login.mp4" loop muted autoPlay />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
