// @flow
import qs from "qs";
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { Dictionaries, DropDownObjItemType } from "../../reducers/dictionaries";

export type Data = {
  "data": Array<DropDownObjItemType>,
}

// eslint-disable-next-line import/prefer-default-export,max-len
export const getDropdownList: (type: Dictionaries, filter?: string, performer?: string) => PromiseResponse<mixed, Data> = (type, filter, performer) => requestService
  .get(environment.endpoints.get.getDropdownList, {
    params: { type, performer, filter },
    paramsSerializer: (params: { [string]: mixed, ... }): string => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  });
