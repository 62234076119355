// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  AppNavSidebar as AppNavSidebarComponent, AppNavSidebarAdditionalComponent,
  AppNavSidebarMenuComponent,
  type AppNavSidebarMenuComponentUrl,
  type AppNavSidebarProps,
} from "@fas/cpa-cabinet-ui";
import { Box, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HEADER_TOOLBAR_HEIGHT } from "../../helpers/constants";
import { LogoLight } from "../../icons";

type Props = {
  links: Array<AppNavSidebarMenuComponentUrl>
}

type Classes = {
  logoContainer: string,
  listComponent: string,
  menuItem: string,
  menuItemIcon: string,
  menuItemText: string,
}

const useDrawerStyles: () => $PropertyType<AppNavSidebarProps, "classes"> = makeStyles((): $PropertyType<AppNavSidebarProps, "classes"> => ({
  // $FlowFixMe
  paper: {
    justifyContent: "flex-start",
    borderRadius: 0,
  },
}));

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: *} => ({
  logoContainer: {
    minHeight: `${HEADER_TOOLBAR_HEIGHT}px`,
  },
  listComponent: {

  },
  menuItem: {
    minHeight: "48px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  menuItemIcon: {
    color: "inherit",
    "&.MuiListItemIcon-root": {
      minWidth: "56px",
    },
  },
  menuItemText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    "& span": {
      fontWeight: "inherit",
    },
  },
}));

const AppNavSidebar: StatelessFunctionalComponent<Props> = ({
  links,
}) => {
  const drawerClasses: $PropertyType<AppNavSidebarProps, "classes"> = useDrawerStyles();
  const classes: Classes = useStyles();

  return (
    <AppNavSidebarComponent
      permanentIconsVisible
      classes={drawerClasses}
      isOpen
    >
      <AppNavSidebarAdditionalComponent>
        <Box
          width={1}
          mr={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.logoContainer}
        >
          <LogoLight />
        </Box>
      </AppNavSidebarAdditionalComponent>
      {/* $FlowFixMe TODO: fix Classes type of AppNavSidebarMenuComponent and AppNavSidebarMenuItem */}
      <AppNavSidebarMenuComponent links={links} classes={classes} />
    </AppNavSidebarComponent>
  );
};

export default AppNavSidebar;
