// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import {
  Navigate, Routes, Route, useLocation, useNavigate,
} from "react-router-dom";
import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import defaultTheme from "../../theme";
import {
  HEADER_TOOLBAR_HEIGHT,
  FOOTER_TOOLBAR_HEIGHT,
  NAV_SIDEBAR_WIDTH,
} from "../../helpers/constants";
// import {
//   isAuthenticated,
// } from "../../services/request";
import Dashboard from "../Dashboard";
import TransactionReport from "../TransactionReport";
import ActiveBrands from "../ActiveBrands";
import PostbackUrls from "../../components/PostbackUrls";
import Login from "../Login";
import PrivateRoute from "../../components/PrivateRoute";
import Navigation from "../../components/Navigation";
// import Header from "../../components/Header";
import Footer from "../../components/Footer";
import type {
  DispatchToProps,
  OwnProps,
  Props,
  StateToProps,
  Classes,
} from "./types/App.types";

// $FlowFixMe
const mapStateToProps: (*) => StateToProps = () => ({});

// $FlowFixMe
const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const useStyles: () => Classes = makeStyles((): {[key: $Keys<Classes>]: Object} => ({
  content: {
    flexGrow: 1,
    minWidth: "500px",
    marginLeft: `${NAV_SIDEBAR_WIDTH}px`,
  },
  body: {
    maxWidth: "1600px",
    minHeight: `calc(100vh - ${HEADER_TOOLBAR_HEIGHT + FOOTER_TOOLBAR_HEIGHT}px)`,
  },
  headerToolbar: {
    height: `${HEADER_TOOLBAR_HEIGHT}px`,
    // marginLeft: `${NAV_SIDEBAR_WIDTH}px`,
    // width: `calc(100vw - ${NAV_SIDEBAR_WIDTH}px)`,
  },
  footerToolbar: {
    height: `${FOOTER_TOOLBAR_HEIGHT}px`,
    // marginLeft: `${NAV_SIDEBAR_WIDTH}px`,
  },
}));

const App: StatelessFunctionalComponent<Props> = () => {
  const classes: Classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box display="flex" flexDirection="column">
        <Navigation />
        <Toolbar className={classes.headerToolbar} />
        <main
          className={classes.content}
        >
          <Box component={Container} py={3} className={classes.body}>
            <Routes>
              <Route path="/login" element={<Login location={location} history={navigate} />} />
              <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
              <Route path="/transactionReport" element={<PrivateRoute component={TransactionReport} />} />
              <Route path="/activeBrands" element={<PrivateRoute component={ActiveBrands} />} />
              <Route path="/postbacks" element={<PrivateRoute component={PostbackUrls} />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </Box>
        </main>
        <Toolbar className={classes.footerToolbar}>
          <Footer />
        </Toolbar>
      </Box>
    </ThemeProvider>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(App);
