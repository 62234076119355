// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import {
  KeyboardArrowRight,
  MailOutline,
} from "@mui/icons-material";
import {
  LoginPasswordIcon,
} from "../../icons";
import { login } from "../../services/request";
import { makeStylesTyped } from "../../helpers/generators";

type Props = {
  onNext: ({expires: number, token: string}) => mixed,
}

const useStyles = makeStylesTyped((theme) => ({
  label: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const AuthField: StatelessFunctionalComponent<Props> = ({ onNext }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setErrorMessage("");
  };

  const onKeyPress = ({ charCode }: { charCode: number }) => {
    const enterCharCode: number = 13;
    if (charCode === enterCharCode) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    login(formData).then((r) => {
      onNext(r.data.payload);
    }).catch((r) => {
      const message: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
      setErrorMessage(message);
      setLoading(false);
    });
  };

  return (
    <Box onKeyPress={onKeyPress}>
      <Typography className={classes.label} variant="body2">
        User Name
      </Typography>
      <TextField
        variant="filled"
        margin="dense"
        required
        fullWidth
        autoFocus
        placeholder="Input your User Name"
        size="small"
        type="text"
        name="login"
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" variant="standard">
              <MailOutline />
            </InputAdornment>
          ),
        }}
        value={formData.login}
        onChange={handleChange}
        className={classes.input}
        error={Boolean(errorMessage)}
      />
      <Typography className={classes.label} variant="body2">
        Password
      </Typography>
      <TextField
        variant="filled"
        margin="dense"
        required
        fullWidth
        size="small"
        placeholder="Input your password"
        type="password"
        name="password"
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" variant="standard">
              <LoginPasswordIcon />
            </InputAdornment>
          ),
        }}
        value={formData.password}
        onChange={handleChange}
        className={classes.input}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
      />
      <Button
        variant="contained"
        color="primary"
        name="next"
        onClick={handleSubmit}
        endIcon={<KeyboardArrowRight />}
        disabled={loading}
        size="large"
      >
        Next
      </Button>
    </Box>
  );
};

export default AuthField;
