// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  IconButton,
  Typography,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PowerSettingsNew } from "@mui/icons-material";
import {
  HEADER_TOOLBAR_HEIGHT,
  NAV_SIDEBAR_WIDTH,
} from "../../helpers/constants";
import { LogoLight } from "../../icons";
import {
  logout,
} from "../../services/request";
import { redirect } from "../../helpers/generators";

type Classes = {
  header: string,
}

type Props = {
  isUserAuthenticated: boolean,
  currentPageTitle: string,
}

const useStyles: (props: { isUserAuthenticated: boolean }) => Classes = makeStyles((
  theme: Theme
): {[key: $Keys<Classes>]: *} => ({
  header: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    display: "flex",
    backdropFilter: "blur(10px)",
    width: ({ isUserAuthenticated }: { isUserAuthenticated: boolean }): string => (isUserAuthenticated ? `calc(100vw - ${NAV_SIDEBAR_WIDTH}px)` : "100%"),
    marginLeft: ({ isUserAuthenticated }: { isUserAuthenticated: boolean }): string => (isUserAuthenticated ? `${NAV_SIDEBAR_WIDTH}px` : "0px"),
    height: `${HEADER_TOOLBAR_HEIGHT}px`,
  },
}));

const Header: StatelessFunctionalComponent<Props> = ({
  isUserAuthenticated,
  currentPageTitle,
}: Props) => {
  const classes: Classes = useStyles({ isUserAuthenticated });

  return (
    <>
      <Box className={classes.header}>
        <Box
          p={1}
          width={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {!isUserAuthenticated && (
            <Box width={1} display="flex" alignItems="center" mr={1} justifyContent="center">
              <LogoLight />
            </Box>
          )}

          {isUserAuthenticated && (
            <>
              <Box ml={2}>
                <Typography component="h1" variant="h1">
                  {currentPageTitle}
                </Typography>
              </Box>
              <Box
                data-testid="action_icons"
                mr={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  name="logout"
                  onClick={() => {
                    logout();
                    redirect("/");
                  }}
                  size="small"
                >
                  <PowerSettingsNew />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Header;
