// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box, Button, FormControl, FormLabel, Grid, InputAdornment, TextField, Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Lock, LockOpen } from "@mui/icons-material";

type Props = {
  tracking: {
    value: string,
    isEditable: boolean,
  },
  onChange: ({ value: string, isEditable: boolean }) => mixed,
}
const useStyles = makeStyles((theme) => ({
  url: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      flexGrow: "1",
      maxWidth: "100%",
      flexBasis: "0",
    },
  },
  action: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "250px",
    },
  },
  helpText: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.3)",
  },
}));

const PostbackUrls: StatelessFunctionalComponent<Props> = ({ tracking, onChange }: Props) => {
  const classes = useStyles();

  return (
    <Box width={1} p={1}>
      <Grid container spacing={1} alignItems="flex-end" className={classes.root}>
        <Grid item className={classes.url}>
          <FormControl fullWidth>
            <FormLabel><Typography>Tracking code</Typography></FormLabel>
            <TextField
              size="small"
              variant="filled"
              className={classes.input}
              onChange={({ target: { value } }) => onChange({ ...tracking, value })}
              value={tracking.value}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end" variant="standard">
                    {tracking.isEditable ? (
                      <LockOpen className={classes.icon} />
                    ) : (
                      <Lock className={classes.icon} />
                    )}
                  </InputAdornment>
                ),
              }}
              disabled={!tracking.isEditable}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.action}>
          <FormControl fullWidth>
            <Box display="flex">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => onChange({ ...tracking, isEditable: !tracking.isEditable })}
              >
                {tracking.isEditable ? "Save changes" : "Edit tracking"}
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
      <Typography variant="body2" className={classes.helpText}>See &quot;how to&quot; block below for the details</Typography>
    </Box>
  );
};

export default PostbackUrls;
