// @flow
import defaultObject from "./default";
import themeV5 from "./v5theme";

const themeObject = {
  defaultObject,
  themeV5,
};

export default themeObject;
