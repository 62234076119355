// @flow
import React from "react";
import {
  SvgIcon,
} from "@mui/material";

const Logo = () => (
  <SvgIcon viewBox="0 0 204 40" style={{ height: "32px", width: "auto" }}>
    <g id="Logo">
      <path d="M32.2,0H7.8C3.5,0,0,3.5,0,7.8v24.4C0,36.5,3.5,40,7.8,40h24.4c4.3,0,7.8-3.5,7.8-7.8V7.8C40,3.5,36.5,0,32.2,0z M9,22.4
        C9,22.4,9,22.4,9,22.4c-0.6,0.6-1.4,0.6-2,0c0,0,0,0,0,0c-0.6-0.5-0.6-1.4,0-2c0,0,0,0,0,0c0.5-0.5,1.4-0.5,2,0c0,0,0,0,0,0
        C9.5,20.9,9.5,21.8,9,22.4z M15.6,33.8c-1-2.1-2.7-3.8-4.8-4.8c2.1-1,3.8-2.7,4.8-4.8c1,2.1,2.7,3.8,4.8,4.8
        C18.3,30,16.6,31.7,15.6,33.8z M30.1,33.8c-2.8,0-5.2-1.8-6.1-4.4l-4.7-14.8l3.5-9.3h0l9.7,28.5H30.1z"
      />
      <g>
        <path d="M64.3,6c1.1,0,1.8,0.6,2,1.7l4.5,24.9c0,0.2-0.1,0.3-0.3,0.3h-3c-1.2,0-2-1.3-2.2-3.8l-0.1-0.9c0-0.1-0.1-0.2-0.3-0.2
          h-5.5c-0.1,0-0.2,0.1-0.3,0.2l-0.4,2.3c0,0,0,0,0,0c-0.3,1.5-1,2.3-2,2.3h-2.6c-0.2,0-0.3-0.1-0.3-0.3l4.7-24.9c0,0,0,0,0,0
          C58.8,6.6,59.4,6,60.5,6H64.3z M62.6,12.2c0-0.3-0.5-0.3-0.5,0L60,24c0,0.2,0.1,0.3,0.3,0.3h4c0.2,0,0.3-0.2,0.3-0.3L62.6,12.2z"
        />
        <path d="M87.7,29.2c0,1.5,0.5,2.7,1.5,3.7h-4.4l-0.7-1.2c-1.8,0.9-3.6,1.4-5.2,1.4c-1,0-1.9-0.2-2.7-0.5c-2.3-0.9-3.5-2.3-3.5-4.3
          v-9.5c0-2,1.1-3.3,3.4-4.2c1.4-0.5,2.8-0.8,4.2-0.8c0.8,0,1.6,0.1,2.4,0.3v-3c0-1.6,0.8-2.4,2.5-2.4h2.5V29.2z M82.8,18.2
          c0-1-0.9-1.5-2.6-1.5s-2.6,0.6-2.6,1.7v9.8c0,1.3,0.9,2,2.6,2.1c0.1,0,0.2,0,0.3,0c1.5,0,2.3-0.6,2.3-1.9V18.2z"
        />
        <path d="M92.1,18.4c0-1.2,0.4-2.2,1.1-2.8c0.8-0.7,1.7-1.1,2.8-1.4c1.1-0.3,2-0.4,2.7-0.5c0.6-0.1,1.2-0.1,1.9-0.1h0.3
          c1.6,0,2.4,0.5,2.4,1.4v1.2c-0.4,0-1.2,0-2.5,0.1c-1.2,0.1-2.2,0.2-2.8,0.5c-0.6,0.3-1,0.8-1,1.5v14.7h-2.6
          c-0.9,0-1.5-0.2-1.7-0.5c-0.5-0.4-0.7-1-0.7-1.8V18.4z"
        />
        <path d="M105.2,28.4v-9.9c0-3.2,2.5-4.9,7.5-4.9c5,0,7.5,1.6,7.5,4.9v9.9c0,3-2.5,4.5-7.5,4.5C107.7,32.9,105.2,31.4,105.2,28.4z
          M110,18.2v10.3c0,1.2,0.9,1.9,2.6,1.9c1.8,0,2.7-0.6,2.7-1.9V18.2c0-1.2-0.9-1.9-2.7-1.9C110.9,16.4,110,17,110,18.2z"
        />
        <path d="M136,14.6c1.3-0.7,3-1,5.1-1c4.7,0,7.1,1.5,7.1,4.6v12.3c0,0.7-0.2,1.3-0.7,1.7c-0.4,0.5-1,0.7-1.7,0.7h-2.4V18.1
          c0-1.2-0.8-1.8-2.4-1.8c-1.8,0-2.7,0.6-2.7,1.8v7.6c0,0.7-0.2,1.3-0.7,1.7c-0.5,0.5-0.9,0.7-1.4,0.7h-2.7v-10
          c0-1.2-0.9-1.8-2.7-1.8c-1.6,0-2.4,0.6-2.4,1.8v12.3c0,0.7-0.2,1.3-0.7,1.7c-0.4,0.5-0.9,0.7-1.7,0.7h-2.4V18.1
          c0-3,2.4-4.6,7.1-4.6C132.9,13.6,134.6,13.9,136,14.6z"
        />
        <path d="M166.7,18.5v4c0,0.7-0.2,1.2-0.6,1.6c-0.4,0.4-0.9,0.6-1.4,0.6h-8v3.9c0,1.2,0.8,1.8,2.5,1.8c1.7,0,2.6-0.6,2.6-1.8v-2.4
          h5v2.2c0,3.1-2.5,4.7-7.6,4.7c-2.3,0-4.1-0.4-5.4-1.2c-1.3-0.8-2-2-2-3.5v-9.9c0-1.5,0.7-2.7,2-3.5c1.3-0.8,3.1-1.2,5.4-1.2
          C164.2,13.7,166.7,15.3,166.7,18.5z M156.6,18.3v3.5h5.1v-3.5c0-1.3-0.9-1.9-2.6-1.9C157.5,16.3,156.6,17,156.6,18.3z"
        />
        <path d="M185.3,29.2c0,1.5,0.5,2.7,1.5,3.7h-4.4l-0.7-1.2c-1.8,0.9-3.6,1.4-5.2,1.4c-1,0-1.9-0.2-2.7-0.5
          c-2.3-0.9-3.5-2.3-3.5-4.3v-9.5c0-2,1.1-3.3,3.4-4.2c1.4-0.5,2.8-0.8,4.2-0.8c0.8,0,1.6,0.1,2.4,0.3v-3c0-1.6,0.8-2.4,2.5-2.4h2.5
          V29.2z M180.4,18.2c0-1-0.9-1.5-2.6-1.5s-2.6,0.6-2.6,1.7v9.8c0,1.3,0.9,2,2.6,2.1c0.1,0,0.2,0,0.3,0c1.5,0,2.3-0.6,2.3-1.9V18.2z"
        />
        <path d="M193,18.1v1h-4.6v-0.6c0-1.5,0.7-2.6,2.2-3.5c1.4-0.8,3-1.3,4.7-1.3s3.3,0.4,4.8,1.3c1.4,0.8,2.2,2,2.2,3.5v10.7
          c0,1.5,0.4,2.7,1.1,3.8h-4l-0.6-1.6c-1.8,1.1-3.5,1.6-5,1.6c-0.9,0-1.6-0.2-2.3-0.5c-2-0.9-3-2.5-3-4.6v-4.4
          c0-1.3,0.6-1.9,1.7-1.9h2.9v6.7c0,1.3,0.7,1.9,2.2,1.9c1.4,0,2.3-0.6,2.5-1.9V18.1c0-1.3-0.8-1.9-2.3-1.9
          C193.8,16.2,193,16.8,193,18.1z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Logo;
