// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { EventPostback } from "@fas/cpa-cabinet-ui";
import type { EventPostbackType } from "@fas/cpa-cabinet-ui/lib/EventPostback/EventPostback.types";

export const eventTypeOptions = [
  {
    title: "Info",
    value: "info",
  },
  {
    title: "Negative",
    value: "negative",
  },
];

export const eventNameOptions = {
  info: [
    {
      title: "Registration",
      value: "registration",
    },
    {
      title: "Confirm",
      value: "confirm",
    },
    {
      title: "Payment",
      value: "payment",
    },
    {
      title: "Try",
      value: "try",
    },
  ],
  negative: [
    {
      title: "Chargeback",
      value: "chargeback",
    },
    {
      title: "Decline",
      value: "decline",
    },
  ],
};

type Props = {
  items: Array<EventPostbackType>,
  onChange: (Array<EventPostbackType>) => mixed,
}

const EventPostbackComponent: StatelessFunctionalComponent<Props> = ({
  items,
  onChange,
}: Props) => {
  const someEdited = items.some(({ isEditable }) => isEditable);
  const handleUpdateEventsPostback: (index: number, item: EventPostbackType) => mixed = (index, item) => {
    if (items[index].type !== item.type) {
      // eslint-disable-next-line no-param-reassign
      item.name = "";
    }
    onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
  };

  const handleAddEventsPostback: (item: EventPostbackType) => mixed = (item) => {
    onChange([...items, item]);
  };

  const handleSaveEventPostback: (index: number, item: EventPostbackType) => mixed = (index, item) => {
    onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
  };

  const handleDeleteEventPostback: (index: number) => mixed = (index) => {
    onChange([...items.slice(0, index), ...items.slice(index + 1)]);
  };
  return (
    <Box p={1} mt={1}>
      <Box mb={2}>
        <Typography variant="h3">Informational events postback</Typography>
      </Box>
      {items.map((postback: EventPostbackType, index: number) => (
        <EventPostback
          key={postback.id}
          data={postback}
          onChange={(item: EventPostbackType): mixed => handleUpdateEventsPostback(index, item)}
          onSave={(item: EventPostbackType): mixed => handleSaveEventPostback(index, item)}
          onDelete={(): mixed => handleDeleteEventPostback(index)}
          disabled={someEdited}
          typeOptions={eventTypeOptions}
          nameOptions={eventNameOptions[postback.type] || []}
          classes={{ button: "MuiButton-contained MuiButton-containedPrimary" }}
        />
      ))}

      { items.length < 5 && (
        <Box pt={2}>
          <Button
            disabled={someEdited}
            variant="contained"
            onClick={() => handleAddEventsPostback({
              id: Math.random().toString(16).slice(2),
              name: "",
              type: "",
              url: "",
              isEditable: true,
            })}
            data-testid="event-postback-add-button"
          >
            Add events postback
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EventPostbackComponent;
