// @flow

export const GET_ACTIVE_BRANDS_SAGA: "GET_ACTIVE_BRANDS_SAGA" = "GET_ACTIVE_BRANDS_SAGA";

export const GET_TRANSACTION_REPORT_SAGA: "GET_TRANSACTION_REPORT_SAGA" = "GET_TRANSACTION_REPORT_SAGA";
export const DOWNLOAD_TRANSACTION_REPORT_SAGA: "DOWNLOAD_TRANSACTION_REPORT_SAGA" = "DOWNLOAD_TRANSACTION_REPORT_SAGA";
export const SET_TRANSACTION_REPORT_IS_ADVANCED: "SET_TRANSACTION_REPORT_IS_ADVANCED" = "SET_TRANSACTION_REPORT_IS_ADVANCED";
export const SET_TRANSACTION_REPORT_FIELDS: "SET_TRANSACTION_REPORT_FIELDS" = "SET_TRANSACTION_REPORT_FIELDS";

export const SET_AUTH_DATA_SAGA: "SET_AUTH_DATA_SAGA" = "SET_AUTH_DATA_SAGA";

export const GET_DICTIONARY_SAGA: "GET_DICTIONARY_SAGA" = "GET_DICTIONARY_SAGA";
export const SET_DICTIONARY: "SET_DICTIONARY" = "SET_DICTIONARY";

export const DASHBOARD_TABLE: "dashboardTable" = "dashboardTable";
export const BRANDS_TABLE: "activeBrands" = "activeBrands";
export const TRANSACTION_TABLE: "transactionTable" = "transactionTable";

export const HEADER_TOOLBAR_HEIGHT: number = 120;
export const FOOTER_TOOLBAR_HEIGHT: number = 65;
export const NAV_SIDEBAR_WIDTH: number = 280;
