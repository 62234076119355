// @flow
import { changeTableFilters, type ChangeTableFilters, type Filters } from "@fas/cpa-state-manager/redux/actions/table";
import { getTableData, getTableFilters, getTableTotalData } from "@fas/cpa-state-manager/services/selectors/table";
import {
  bindActionCreators,
  type Dispatch,
} from "redux";
import { connect } from "react-redux";
import { getDashboardDataSaga } from "@fas/cpa-state-manager/redux/actions/dashboard";
import getLoadingState from "@fas/cpa-state-manager/services/selectors/loading";
import Dashboard from "../../components/Dashboard";
import type {
  Props,
  OwnProps,
  StateToProps,
  DispatchToProps,
} from "./types/Dashboard.types";
import { DASHBOARD_TABLE } from "../../helpers/constants";

const mapStateToProps: (state: *) => StateToProps = (state) => ({
  data: getTableData(state, DASHBOARD_TABLE),
  // $FlowFixMe conflict type with lib
  dataTotal: getTableTotalData(state, DASHBOARD_TABLE),
  filters: getTableFilters(state, DASHBOARD_TABLE),
  loading: getLoadingState(state, "getDashboardDataLoading"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetDashboardData: getDashboardDataSaga,
  onFiltersChange: (filters: Filters): ChangeTableFilters => changeTableFilters(DASHBOARD_TABLE, filters),
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Dashboard);
