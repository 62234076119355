// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useLocation } from "react-router-dom";
import type { AppNavSidebarMenuComponentUrl } from "@fas/cpa-cabinet-ui";
import {
  Equalizer, Link, StarOutline, WorkOutline,
} from "@mui/icons-material";
import { isAuthenticated } from "../../services/request";
import Header from "../Header";
import AppNavSidebar from "../AppNavSidebar";

type Props = {}

const Navigation: StatelessFunctionalComponent<Props> = () => {
  const isUserAuthenticated: boolean = isAuthenticated();
  const { pathname }: * = useLocation();

  const links: Array<AppNavSidebarMenuComponentUrl> = [
    { url: "/dashboard", text: "Dashboard", icon: <WorkOutline /> },
    { url: "/transactionReport", text: "Transaction Report", icon: <Equalizer /> },
    { url: "/activeBrands", text: "Active Brands", icon: <StarOutline /> },
    { url: "/postbacks", text: "Postback URLs", icon: <Link /> },
  ];

  // eslint-disable-next-line no-unused-vars
  const pagesMap: {[key: string]: string} = links
    .reduce((map: {[key: string]: string}, link: AppNavSidebarMenuComponentUrl): {[key: string]: string} => {
      const { url, text }: AppNavSidebarMenuComponentUrl = link;
      if (typeof url === "string" && text) {
        return {
          [url]: text,
          ...map,
        };
      }
      return map;
    }, {});

  const currentPage: string = pagesMap[pathname] || "";

  return (
    <>
      <Header
        isUserAuthenticated={isUserAuthenticated}
        currentPageTitle={currentPage}
      />
      {isUserAuthenticated && (
        <AppNavSidebar links={links} />
      )}
    </>
  );
};

export default Navigation;
