// @flow
import React, { type Node } from "react";
import environment from "environment";
import {
  Container,
  Grid,
  Box,
  Link,
  Typography,
  type Theme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "../../services/request";
import {
  NAV_SIDEBAR_WIDTH,
} from "../../helpers/constants";
import { makeStylesTyped } from "../../helpers/generators";

type Classes = {
  footer: string,
  policy: string,
  terms: string,
  copyright: string,
}

const useStyles: (props: { isUserAuthenticated: boolean }) => Classes = makeStylesTyped((theme: Theme): * => ({
  footer: {
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.paper,
    width: ({ isUserAuthenticated }: { isUserAuthenticated: boolean }): string => (isUserAuthenticated ? `calc(100vw - ${NAV_SIDEBAR_WIDTH}px)` : "100%"),
    marginLeft: ({ isUserAuthenticated }: { isUserAuthenticated: boolean }): string => (isUserAuthenticated ? `${NAV_SIDEBAR_WIDTH}px` : "0px"),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  policy: {
    padding: theme.spacing(1),
    color: theme.palette.text.disabled,
    fontWeight: 600,
  },
  terms: {
    padding: theme.spacing(1),
    color: theme.palette.text.disabled,
    fontWeight: 600,
  },
  copyright: {
    color: theme.palette.text.disabled,
    fontWeight: 600,
  },
}));

function Footer(): Node {
  const isUserAuthenticated: boolean = isAuthenticated();
  const classes: Classes = useStyles({ isUserAuthenticated });
  useLocation();

  return (
    <Box className={classes.footer}>
      <Container>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.copyright} variant="body2">© 2020 by Saltire Connect Limited</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Link variant="body2" underline="none" className={classes.policy} target="_blank" href={environment.links.policy}>Privacy Policy</Link>
              <Link variant="body2" underline="none" className={classes.terms} target="_blank" href={environment.links.terms}>Terms of Use</Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default Footer;
