// @flow

const themeDefaultObject: mixed = {
  theme: "default",
  props: {},
  typography: {
    fontFamily: "'Jost', sans-serif",
  },
  shape: {
    borderRadius: "15px",
  },
  palette: {
    background: {
      default: "#FFF",
    },
    primary: {
      main: "#000",
      light: "#FFF",
      dark: "#000000bb",
    },
    secondary: {
      main: "#ffba08",
      light: "rgba(0, 0, 0, 0.03)",
    },
    success: {
      main: "#5ed262",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.65)",
      disabled: "rgba(0, 0, 0, 0.4)",
    },
  },
  buttons: {
    main: {},
    secondary: {},
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "50px",
        fontWeight: 600,
        lineHeight: 1.4,
      },
      h3: {
        fontSize: "36px",
        fontWeight: 600,
        lineHeight: 1.25,
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "separate",
        borderSpacing: "0px 10px",
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        "& td:first-of-type": {
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
        },
        "& td:last-child": {
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
        },
      },
      head: {
        backgroundColor: "#FFF",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
    MuiInputBase: {
      root: {
        color: "rgba(0, 0, 0, 0.65)",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        height: "50px",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      },
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: "rgba(0, 0, 0, 0.65)",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        borderRadius: "15px",
      },
      input: {
        borderRadius: "inherit",
        paddingTop: "16px",
        paddingBottom: "16px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "15px",
      },
      notchedOutline: {
        border: "none",
      },
      inputMarginDense: {
        paddingTop: "8.5px",
        paddingBottom: "8.5px",
      },
    },
    MuiFormLabel: {
      root: {
        paddingBottom: 8,
        "& .MuiTypography-body1": {
          fontWeight: 500,
          color: "#000",
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        color: "rgba(0, 0, 0, 0.65)",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        paddingTop: "4px !important", // important override - .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
        paddingBottom: "4px !important",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "rgba(0, 0, 0, 0.3)",
        "&$checked": {
          color: "#1E88E5",
        },
      },
    },
    MuiButton: {
      root: {
        height: "50px",
      },
      outlinedSizeLarge: {
        padding: "11px 22px",
      },
      containedPrimary: {
        minWidth: "max-content",
        fontWeight: 600,
        backgroundColor: "#ffba08",
        color: "#000",
        "&:hover": {
          backgroundColor: "#ffba08",
          opacity: "0.85",
          boxShadow: "none",
        },
        boxShadow: "none",
      },
      containedSecondary: {
        minWidth: "max-content",
        fontWeight: 700,
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        color: "#000",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          boxShadow: "none",
        },
        boxShadow: "none",
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: "15px",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      },
    },
  },
};

export default themeDefaultObject;
