// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  DateRangeOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import type { PropsInputComponent } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";
import { makeStylesTyped } from "../../helpers/generators";

type Classes = {
  input: string,
}

const useStyles = makeStylesTyped(() => ({
  input: {
    cursor: "pointer",
  },
}));

const DateInputComponent: StatelessFunctionalComponent<PropsInputComponent> = ({
  startDate, endDate, onIconClick, disabled,
}: PropsInputComponent) => {
  const classes: Classes = useStyles();

  return (
    <TextField
      disabled={disabled}
      size="small"
      variant="filled"
      onClick={onIconClick}
      value={`${dayjs(startDate).format("DD MM YYYY")} - ${dayjs(endDate).format("DD MM YYYY")}`}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        className: classes.input,
        readOnly: true,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" variant="standard">
            <DateRangeOutlined />
          </InputAdornment>
        ),
        inputProps: {
          className: classes.input,
        },
      }}
    />
  );
};

export default DateInputComponent;
